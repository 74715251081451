<!--
 * @Author: 智客云网络科技
 * @Date: 2021-04-18 17:43:08 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-11 15:41:26
 * @Description: 账号管理
 * @FilePath: \src\views\Frame\Account\AccountManage.vue
-->
<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="success"
        icon="el-icon-plus"
        @click="_OpenNewAccountModel()"
      >
        添加新用户
      </el-button>
      <el-button
        size="small"
        type="warning"
        icon="el-icon-refresh"
        @click="_Load()"
      >
        重载数据
      </el-button>
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 用户名 -->
      <el-table-column
        label="用户名"
        align="center"
        prop="username"
        show-overflow-tooltip
        width="200px"
      ></el-table-column>

      <!-- 昵称 -->
      <el-table-column
        label="昵称"
        align="center"
        prop="nickname"
        show-overflow-tooltip
        width="200px"
      ></el-table-column>

      <!-- 头像 -->
      <el-table-column label="头像" align="center" width="80px">
        <template slot-scope="s">
          <el-avatar
            :size="30"
            class="user-avatar"
            :src="base_url + '/resources/' + s.row.avatar"
          >
            <img src="@/assets/avatar/avatar-load-fail.png" />
          </el-avatar>
        </template>
      </el-table-column>

      <!-- 当前状态 -->
      <el-table-column label="状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 手机号 -->
      <el-table-column
        label="手机号"
        align="center"
        prop="phone"
        width="130px"
      ></el-table-column>

      <!-- 邮箱 -->
      <el-table-column
        label="邮箱"
        align="center"
        prop="email"
        show-overflow-tooltip
        width="200px"
      ></el-table-column>

      <!-- 注册时间 -->
      <el-table-column
        label="注册时间"
        align="center"
        prop="gen_time"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateAccountModel(s.row.kuser_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelAccount(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[15, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-account ref="nu-account"></nu-account>
  </div>
</template>

<script>
import { getUserTable, delAccountByAdmin } from "./api"; //页面专有接口
import NuAccount from "./NUAccount";
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "AccountManage",
  components: {
    NuAccount,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 15, //分页行数
        dataCount: 0, //数据总行数
      },
      base_url: process.env.VUE_APP_BASE_URL, //
      TableData: [], //数据集合
    };
  },
  watch: {
    //监听组织ID变动刷新表格
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //载入数据
    _Load() {
      let p = this.p;
      const params = {
        pageNo: p.pageNo,
        pageSize: p.pageSize,
        kor_id: this.p.kor_id,
      };
      getUserTable(params)
        .then((res) => {
          this.TableData = res.data.data; //数据
          this.p.dataCount = res.data.dataCount; //数据总行数
        })
        .catch((err) => {
          this.TableData = []; // 数据
          if (err.code === 101023) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    // 添加
    _OpenNewAccountModel() {
      this.$refs["nu-account"].open(0, this.p.kor_id);
    },

    // 修改
    _OpenUpdateAccountModel(uid) {
      this.$refs["nu-account"].open(uid, this.p.kor_id);
    },

    // 删除
    _DelAccount(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除账号【" +
          data.username +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delAccountByAdmin({ user_id: data.kuser_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>

<style></style>
